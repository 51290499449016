<template>
    <div style="display: flex; flex-direction: row-reverse; cursor: pointer;"
        v-on:click="handler"
    v-if="$partnerACL.isShowButton(menuButton)">
        <v-icon medium color="#FFF">
            mdi-bell
        </v-icon>
    </div>
</template>

<script>
export default {
    name: "IcButton",
    props: {
        name: {
            type: String,
            required: true
        },
        menuButton: {
            type: String,
            required: true
        },
        handler: {
            type: Function,
            required: true
        }
    }
}
</script>