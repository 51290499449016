<template>
    <v-container class="home body-card" fluid fill-height>
      <!-- <v-container class="primary"> -->
      <notification-popup
        :dialog="notifWaitGetGenerateTicket"
        :headerMsg="$t('CreateTicket')"
        :contentMsg="$t('ThankYouForYourPurchase')"
        :contentMsgList="[$t('PleaseWaitCreateTicket')]"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('labelYes')"
        colorIcon="success"
        Icon="mdi-reload"
        typeModal="dialog"
        :disableActions="true"
      />
      <notification-popup
        :dialog="notifGenerateTicketSucced"
        :contentMsg="$t('YourTicketReadyToUse')+ ' ' +$t('PleaseGoToMyTicket')"
        :headerMsg="$t('IsSuccess')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('GoToMyTicket')"
        imgSource="notification/success2.svg"
        disableBtnNo="true"
        typeModal="transaction"
        :onHandlerYes="buttonOke"
      />
      <notification-popup
        :dialog="notifGenerateTicketFailed"
        contentMsgAlign="left"
        :contentMsg="$t('WeAreHavingIssueGeneratingYourTicket')"
        :contentMsgList="[$t('DontWorryYourTransactionCanceled'),$t('PleaseTryAgainLaterTicketPurchase')]"
        :headerMsg="$t('WeAreSorry')"
        :nameBtnYes="$t('GoToTransactionHistory')"
        :nameBtnNo="$t('labelNo')"
        colorIcon="warning"
        Icon="mdi-alert-circle-outline"
        typeModal="transaction"
        disableBtnNo="true"
        :onHandlerYes="buttonOke"
      />
      <notification-popup
        :dialog="notifPaymentFailed"
        :contentMsg="$t('PaymentFailedPleaseTryAgain')"
        :headerMsg="$t('WeAreSorry')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('GoToTransactionHistory')"
        colorIcon="danger"
        typeModal="transaction"
        Icon="mdi-close-circle"
        disableBtnNo="true"
        imgSource="notification/failed.svg"
        :onHandlerYes="buttonOke"
      />
      <notification-popup
        :dialog="notifPaymentCancel"
        :contentMsg="$t('YourPaymentCanceled')"
        :headerMsg="$t('Canceled')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('Close')"
        colorIcon="danger"
        typeModal="transaction"
        Icon="mdi-close-circle"
        disableBtnNo="true"
        imgSource="notification/failed.svg"
        :onHandlerYes="buttonOke"
      />
      <notification-popup
        :dialog="notifWhitelistAccount"
        :contentMsg="$t('AccountWhitelist')"
        :headerMsg="$t('WeAreSorry')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('Close')"
        colorIcon="danger"
        typeModal="transaction"
        Icon="mdi-close-circle"
        disableBtnNo="true"
        imgSource="notification/failed.svg"
        :onHandlerYes="buttonOke"
      />

      <v-row class="welcome-header" :style="`background-color: ${$partnerACL.getBackgroundColorApp()};color: ${$partnerACL.getTitleColor()}`">
        <v-col
          cols="12"
        />
        <v-col
          v-if="gender === 'female'"
          class="pl-5 d-flex col col-12 pb-3 mt-2 "
          cols="12"
          style="align-items: center; justify-content: space-between;"
        >
          <!-- <img
            :src="require('../../assets/female-profile.png')"
            width="30px"
            alt="John"
            class="around"
          /> -->
          <div class="pl-2">
            <h3 class="title-login font-weight-regular" >
              {{ $t("Welcome") }}
            </h3>
            <h2 class="font-weight-regular full-name">{{ full_name }}</h2>
          </div>
          <ic-button
            name="mdi-bell"
            menu-button="notification"
            :handler="goNotification"
          />
        </v-col>
        <v-col
          v-if="gender === 'male'"
          class="pl-5 d-flex col col-12 pb-3 mt-2 "
          cols="12"
          style="align-items: center; justify-content: space-between;"
        >
          <!-- <img
            :src="require('../../assets/photo-profile.png')"
            width="30px"
            alt="man ava"
            class="around"
          /> -->
          <div class="pl-2">
            <h3 class="title-login font-weight-regular">
              {{ $t("Welcome") }}
            </h3>
            <h2 class="font-weight-regular full-name">{{ full_name }}</h2>
          </div>
          <ic-button
            name="mdi-bell"
            menu-button="notification"
            :handler="goNotification"
          />
        </v-col>
        <v-col
          v-if="gender === ''"
          class="pl-5 d-flex col col-12 pb-3 mt-2 "
          cols="12"
          style="align-items: center; justify-content: space-between;"
        >
          <!-- <img src="" alt="None" class="around" /> -->
          <div class="pl-2">
            <h3 class="title-login font-weight-regular">
              {{ $t("Welcome") }}
            </h3>
            <h2 class="font-weight-regular full-name">{{ full_name }}</h2>
          </div>
          <ic-button
            name="mdi-bell"
            menu-button="notification"
            :handler="goNotification"
          />
        </v-col>
        <v-col
          class="body-2 font-weight-medium text-right"
          cols="12"
          align="end"
        >
          <a v-if="$partnerACL.isShowButton('qr_code')" @click="clickMyQR" style="text-decoration: none" :style="`color: ${$partnerACL.getTitleColor()}`">{{
            $t("MyQRCode")
          }}</a>
        </v-col>
      </v-row>
      <!-- <v-row no-gutters  class="onHover" @click="ClickPaymentMethod()">
          <v-col>
            <v-card outlined class="secondary lighten-5 ma-4 rounded-card">
              <v-list class="transparent">
                <v-list-item>
                <v-list-item-icon>
                    <v-icon>mdi-wallet</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <h1 class="primary--text">Rp 1.000.000</h1>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{$t('titlePaymentMethod')}}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>-->
      <!-- </v-container>  -->
      <!-- <v-row>
        <v-col class="pb-0">
          <span class="pre-title">{{ $t("HomePreTitle") }}</span>
          <p class="mt--10 mb-0">
            <b class="title">{{ $t("HomeTitle") }}</b>
          </p>
        </v-col>
      </v-row> -->
      <v-row
        v-for="item in menuHome "
        :key="item.title"
      >
        <v-col class="pb-0">
          <v-card
            :to="isGranted(item.path) ? item.path : ''"
            class="mx-auto shadow rounded-lg"
            color="#FFF"
            dark
          >
            <v-card-actions class="btn-overflow">
              <v-list-item class="grow">
                <v-list-item-avatar v-if="item.icon!=''" color="white">
                  <!-- <img :src="item.icon" alt="item.title" /> -->
                  <object type="image/svg+xml" :data="item.icon" style="fill:red;color:red">
                    {{item.title}} <!-- fallback image in CSS -->
                  </object>

                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="sub-menu">
                    {{ $t(item.title) }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <div v-if="initialPath != undefined ? initialPath.login == '/login' : true">
        <v-row align="center">
          <v-col cols="8" class="pb-0">
            <b class="title">{{ $t("RecentTrip") }}</b>
          </v-col>
          <v-col cols="4" class="text-end pb-0" v-if="itemRT.length !== 0 && $partnerACL.findMenu('MyTrip')">
            <span class="sub-menu" :style="`color: ${$partnerACL.getButtonColor()}`" @click="clickMyTrip()">{{
              $t("ViewAll")
            }}</span>
          </v-col>
        </v-row>
        <div v-if="loadComponent">
          <v-row v-for="(item, index) in itemRT" :key="index">
            <CardRecentTrip
              :RouteName="item.fare_name"
              :OriginalName="item.original_loc"
              :OriginalAcronym="item.orig_loc"
              :DestinationAcronym="item.dest_loc"
              :DestinationName="item.destination_loc"
              :DateTrip="item.used_date"
              :TicketUUID="item.ticket_id"
              :TypeTrip="item.fare_type"
              :MaxTrip="item.max_trip"
              :MaxTripPerDay="item.max_trip_perday"
              :UsedTicket="item.used_ticket"
              :FareName="item.fare_name"
              :FareRule="item.fare_rule"
              :DepartureTime="item.out_timestamp"
              :ExpiredNote="getExpiredNote(item.fare_rule)"
              :Price="convertPriceByCurrency(Number(item.price), item.currency_code)"
              :CurrencyCode="localCurrency"
              :FleetTypeDetail="item.fleet_type_detail"
            />
          </v-row>

        </div>

        <v-row v-if="loadComponent && itemRT.length === 0" justify="center" class="mt-3 mb-5">
          <v-col class="d-flex justify-center pb-0" cols="12">
            <v-img
              :src="require('../../assets/icon/no-recent-trip.svg')"
              max-width="150px"
            ></v-img>
          </v-col>
          <v-col class="text-center py-0">
            <h3 class="font-weight-bold">{{ $t("NoRecentTrip") }}</h3>
          </v-col>
        </v-row>
      </div>
      <!-- <v-row>
          <v-col>
            <v-carousel
              :continuous="false"
              :cycle="cycle"
              :show-arrows="false"
              hide-delimiter-background
              delimiter-icon="mdi-minus"
              height="300"
            >
              <v-carousel-item v-for="(slide, i) in slides" :key="i">
                <v-sheet :color="colors[i]" height="100%" tile>
                  <v-row class="fill-height" align="center" justify="center">
                    <div class="display-3">{{ slide }} Slide</div>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row> -->
    </v-container>
</template>

<script>
// @ is an alias to /src
import CardRecentTrip from "@/components/CardRecentTrip.vue";
import store from "@/store";
import moment from "moment";
import NotificationPopup from "@/components/NotificationPopup.vue";
import IcButton from "@/components/IcButton.vue";

export default {
  name: "Home",
  components: { CardRecentTrip, NotificationPopup, IcButton },
  data() {
    return {
      psg_name: store.state.auth.userData.user_name,
      full_name: "",
      gender: "",
      colors: [
        "green",
        "secondary",
        "yellow darken-4",
        "red lighten-2",
        "orange darken-1",
      ],
      cycle: false,
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
      itemRT: [],
      notifWaitGetGenerateTicket: false,
      notifPaymentSuccess: false,
      notifPaymentFailed: false,
      notifGenerateTicketSucced: false,
      notifGenerateTicketFailed: false,
      notifPaymentCancel: false,
      notifPaymentExpired: false,
      notifWhitelistAccount: false,
      loadComponent: false,
      localCurrency: localStorage.getItem('currency') || 'USD'
    };
  },
  computed: {
    userData() {
      return this.$store.state.auth.userData;
    },
    user() {
      return this.$store.state.auth.userData.user_name;
    },
    provider() {
      return this.$store.state.auth.provider;
    },
    menuHome() {
      return this.$partnerACL.getSubMenu();
      // return this.$partnerACL.getSubMenuList()
      // .map((smenu) => {
      //   return {
      //     title: smenu.sub_menu_name,
      //     icon: smenu.sub_menu_icon != "" ? require(`../../assets/home/${smenu.sub_menu_icon}.svg`) : "",
      //     path: smenu.sub_menu_path
      //   }
      // });
    },
    userRole() {
      return this.$store.state.auth.user_role;
    },
    initialPath() {
      return this.$store.state.auth.initialPath
    }
  },
  watch: {
    user() {
      location.reload();
    },
  },
  async created() {
    const payloadCurrency = {
      currencyCode: localStorage.getItem('currency') || "IDR",
    };
    await this.$store.dispatch("purchaseticket/getCurrencyConversionRate", payloadCurrency)
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD MM YYYY");
      }
    },
    clickMyQR() {
      if (this.userRole != 'whitelist') {
        const qrButton = this.$partnerACL.getMetaButton('qr_code');
        if (qrButton) {
          if (qrButton.menu_type == "wsc_menu") {
            this.$router.push({
              name: qrButton.redirect_name
            })
          } else {
            window.location = qrButton.redirect_path;
          }
        }
      }
    },
    clickMyTrip() {
      this.$router.push({
        name: "MyTrip",
      });
    },
    buttonOke() {
      if (this.notifGenerateTicketSucced && this.provider == 'ACASIA') this.$router.push({ name: "MyTicket" })
      else if (this.notifGenerateTicketSucced && this.provider == 'GOBUS') this.$router.push({ name: "MyTicketGobus" })
      else if (this.notifGenerateTicketFailed || this.notifPaymentFailed) this.$router.push({ name: "TransactionHistory" })
      this.notifPaymentSuccess = false;
      this.notifPaymentFailed = false;
      this.notifPaymentCancel = false;
      this.notifPaymentExpired = false;
      this.notifWaitGetGenerateTicket = false;
      this.notifGenerateTicketSucced = false;
      this.notifGenerateTicketFailed = false;
      this.notifWhitelistAccount = false;
    },
    convertPriceByCurrency(price, currency) {
      const conversionRates = this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.conversionRates : null;
      const priceResult = price * conversionRates[currency];
      return priceResult;
    },
    getExpiredNote(fareRule) {
      return fareRule.expired_on_same_day ? 'ExpiredOnSameDay' :
        fareRule.valid_days != null && !fareRule.expired_on_same_day ? 'ExpiredAfterPurchase' :
          fareRule.expired_after_usage ? 'ExpiredAfterUsage' :
            fareRule.validate_close_date == null ? 'Unlimited' :
              'ValidationPeriod'
    },
    async lastestPayment(payment_token) {
      this.$store.commit("auth/IS_LOADING", true);
      // var invoiceNumber = '';
      await this.$store
        .dispatch(
          "purchaseticket/getStatusByPaymentToken",
          payment_token
        )
        .then((response) => {
          invoiceNumber = response.transaction_detail.order_id;
          var status =
            response.payment_status_history[
            response.payment_status_history.length - 1
            ];
          this.$store.commit("auth/IS_LOADING", false);
          switch (status.payment_status) {
            case "paid":
              this.notifPaymentSuccess = true;
              break;
            case "fail":
              this.notifPaymentFailed = true;
              break;
            case "canceled":
              this.notifPaymentCancel = true
              break;
            case "expired":
              this.notifPaymentExpired = true
              break;
          }
        })
        .catch((err) => {
          console.log(err);
          this.notifWaitGetGenerateTicket = false;
          this.$store.commit("auth/IS_LOADING", false);
        });
      // this.getGenerateTicketAvailability(invoiceNumber);
    },
    async lastestPaymentOrder(order_id) {
      this.$store.commit("auth/IS_LOADING", true);
      await this.$store
        .dispatch(
          "purchaseticket/getStatusByOrderID",
          order_id
        )
        .then((response) => {
          var status = response.payment_status;
          this.$store.commit("auth/IS_LOADING", false);
          switch (status) {
            case "paid":
              this.notifPaymentSuccess = true;
              break;
            case "fail":
              this.notifPaymentFailed = true;
              break;
            case "canceled":
              this.notifPaymentCancel = true
              break;
            case "expired":
              this.notifPaymentExpired = true
              break;
          }
        })
        .catch((err) => {
          console.log(err);
          this.notifWaitGetGenerateTicket = false;
          this.$store.commit("auth/IS_LOADING", false);
        });
      // this.getGenerateTicketAvailability(order_id);
    },
    async getGenerateTicketAvailability(invoiceNumber) {
      if (this.notifPaymentSuccess) {
        this.notifWaitGetGenerateTicket = true;
        const payload = {
          invoiceNumber: invoiceNumber
        }
        setTimeout(async () => {
          await this.$store
            .dispatch(
              "myticket/getTicketAvailability",
              payload
            )
            .then((response) => {
              if (response[0].generate_ticket) this.notifGenerateTicketSucced = true;
              else this.notifGenerateTicketFailed = true;
            })
            .catch((err) => {
              console.log(err);
              this.notifGenerateTicketFailed = true;
            });
          this.$store.commit("auth/IS_LOADING", false);
          this.notifWaitGetGenerateTicket = false;
        }, 2000);
      } else {
        this.$store.commit("auth/IS_LOADING", false);
        this.notifWaitGetGenerateTicket = false;
      }
    },
    // async getDetailCustomer() {
    //   await this.$store
    //     .dispatch("customer/GetDataById")
    //     .then((response) => {
    //       this.full_name =
    //         response.data.psg_first_name + " " + response.data.psg_last_name;
    //       this.gender = response.data.psg_gender;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    async getRecentTrip() {
      const param = {
        payload: {
          is_recent: true
        },
        page: 1,
        limit: 4,
      };
      await this.$store
        .dispatch("mytrip/triphistory", param)
        .then((response) => {
          this.itemRT = [];
          for (let data of response.data) {
            this.itemRT.push(data);
          }
          this.loadComponent = true
        })
        .catch((err) => {
          this.loadComponent = true
          console.log(err);
        });
    },
    popStateListener(event) {
      // prevented back from home page
      event.preventDefault();
      history.pushState(null, document.title, location.href);
    },
    async redirectToPaymentPage() {
      window.location.replace(process.env.VUE_APP_UPG_WEB + "/#/payment-status?tokentrx=" + this.$route.query.tokentrx);
    },
    goNotification() {
      if (this.userRole != 'whitelist') {
        const notification = this.$partnerACL.getMetaButton('notification');
        if (notification) {
          if (notification.menu_type == "wsc_menu") {
            this.$router.push({
              name: notification.redirect_name
            })
          } else {
            window.location = notification.redirect_path;
          }
        }
      }
    },
    isGranted(path) {
      if (this.userRole == 'whitelist') {
        if (path == '/about') {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
  },
  async mounted() {
    if (this.userRole == 'whitelist') {
      this.notifWhitelistAccount = true
    }
    if (this.$route.query.lang) {
      this.$i18n.locale = this.$route.query.lang;
      localStorage.setItem("language", this.$route.query.lang);
    }
    if (this.$route.query.tokentrx != undefined) {
      await this.redirectToPaymentPage()
    }
    await this.getRecentTrip();
    // await this.getDetailCustomer();
    this.full_name = this.userData.fullName

    if (this.$route.query.payment_token != undefined) {
      await this.lastestPayment(this.$route.query.payment_token);
    }
    if (this.$route.query.orderId != undefined) {
      await this.lastestPaymentOrder(this.$route.query.orderId);
    }

    await this.$store.dispatch("auth/refreshCustomerUserData")
    history.pushState(null, document.title, location.href);
    window.addEventListener('popstate', this.popStateListener);
    this.$store.commit("auth/IS_LOADING", false);
    this.gender = this.userData.gender;
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.popStateListener);
  }
};
</script>

<style lang="scss" scoped>
.body-card {
  background-color: #f5fdf7;
  height: 100%;
  width: 100%;
  padding-top: 0;
  display: block;
}

.btn-overflow {
  max-width: inherit;
  position: relative;
  overflow: overlay;
}

// .test {
// background: linear-gradient(180deg, #479D4A 0%, #5DB960 100%);
// background: #4bb14e !important;
// }
.bg-img {
  background: #479d4a;
  background-image: url(/img/header-decorations.1a1215dc.svg),
    linear-gradient(180deg, #479d4a 0%, #5db960 100%);
  background-position: right bottom;
  background-repeat: no-repeat;
  /* The image used */
  // background: linear-gradient(180deg, #479D4A 0%, #5DB960 100%);
  // background-image: url("../../assets/header-decorations.svg");
  // background-position: right bottom;
  // background-repeat: no-repeat;
  // background-size: inherit;
  // position: relative; url(/img/header-decorations.1a1215dc.svg)
}

.shadow {
  box-shadow: 0px 3px 1px -10px rgb(0 0 0 / 50%),
    0px 2px 2px 0px rgb(0 0 0 / 5%), 0px 1px 5px 0px rgb(0 0 0 / 2%) !important;
}

.around {
  border-radius: 50% !important;
  min-width: 70px;
}

.color {
  background-color: #e9f9eb;
  // box-shadow: none;
  border-color: #b6b6b6;
  // box-shadow: none;
}

.full-name {
  overflow-wrap: anywhere;
  height: 2em;
  margin-right: 2.5em;
}

.pre-title {
  font-size: 12px !important;
}

.title {
  font-size: 18px !important;
  color: #000;
}

.sub-menu {
  font-size: 14px;
  color: black;
}

.mt--10 {
  margin-top: -10px !important;
}

.v-avatar {
  border-radius: 0% !important;
}

.text-green {
  color: #5ed799 !important;
}

.mt--12 {
  margin-top: 12%;
}

.text-header {
  max-width: max-content;
  position: absolute;
  white-space: nowrap;
}

.welcoming-header {
  width: 100%;
  overflow: scroll;
  position: sticky;
}

/* If the screen size is 673px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .title-Category {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
  }

  .expl-Category {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 118.88%;
  }

  .onHover {
    cursor: pointer;
  }

  .welcome-text {
    margin-left: -20;
  }
}

/* If the screen size is 672 or less, set the font-size of <div> to 30px */
@media only screen and (max-width: 600px) {
  .title-Category {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
  }

  .expl-Category {
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 118.88%;
  }

  .onHover {
    cursor: pointer;
  }

  .welcome-text {
    margin-left: 0%;
  }

  .h3-title {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 1rem;
  }
}
</style>
